import { FormControlData, FormControlValue, CreateFormControlProps, ICollectionTextArea } from "../model";
import { createFormControl } from "solid-forms";
import { Label, TextArea, Error } from "../views";

export function createTextArea<V extends FormControlValue, D extends FormControlData = FormControlData>(
  ...args: CreateFormControlProps<V, D>
): ICollectionTextArea<V, D> {
  const control = createFormControl<V, D>(...args);
  // console.log("control id ", control.id);
  const name = String(control.id);
  return {
    control,
    name,
    Label: (props) => <Label control={control} for={name} {...props} />,
    Error: (props) => <Error control={control} {...props} />,
    TextArea: (props) => <TextArea control={control} name={name} {...props} />,
  };
}
